import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Avatar,
  Badge,
  Button,
  Card,
  CorezoidLightTheme as theme,
  DateUtils,
  Icon,
  Label,
  Stack,
} from 'mw-style-react';
import {
  NOTIFY_LEVEL,
  SHOW_NOTIFY,
  HIDE_NOTIFY,
  DATE_FORMAT_5,
} from 'constants';
import AppUtils from '@control-front-end/utils/utils';
import {
  WS_MEETING_REMINDER_ACTOR,
  WS_MEETING_STARTED_ACTOR,
} from '@control-front-end/common/constants/meeting';
import { useIntl, useReduxAction } from 'hooks';
import mes from 'globalIntl';
import scss from '@control-front-end/common/styles/notifyContent.scss';
import history from '../store/history';

const SECONDS_PER_MINUTE = 60;
const DELAY_TO_SHOW_REMINDER_MS = 30000;
const NOTIFY_BORDER_RADIUS = Card.BORDER_RADIUS.xxlarge;

function EventAvatar() {
  return (
    <Avatar className={scss.avatar} size="large" bgColor={theme.palette.white}>
      <Icon type="event" colorType={Icon.COLOR.primary} />
    </Avatar>
  );
}

function NotifyLabel({ text }) {
  return <Label className={scss.label} value={text} fontWeight="semibold" />;
}

function useScheduleMeetingNotifier() {
  const t = useIntl();
  const dispatch = useDispatch();

  const hideNotify = (id) => {
    dispatch({
      type: HIDE_NOTIFY.REQUEST,
      payload: { id },
    });
  };

  const showNotify = ({ id, extra }) => {
    dispatch({
      type: SHOW_NOTIFY.REQUEST,
      payload: {
        id,
        type: NOTIFY_LEVEL.PRIMARY,
        borderRadius: NOTIFY_BORDER_RADIUS,
        ...extra,
      },
    });
  };

  const handleMeetingNavigation = (id, { accId, id: modelId }) => {
    history.push(`/actors_graph/${accId}/view/${modelId}?tab=meeting`);
    hideNotify(id);
  };

  const renderNotificationContent = ({ renderFunc, ...props }) => (
    <Stack.H
      size="small"
      fullWidth
      alignItems="center"
      justifyContent="spaceBetween"
    >
      {renderFunc(props)}
    </Stack.H>
  );

  const renderMeetingReminder = ({ id, model, minutesLeft, label }) => (
    <>
      <EventAvatar />
      <Stack.V size="none" fullWidth>
        <NotifyLabel text={t(mes.meetingReminder, { minutesLeft })} />
        <NotifyLabel text={label} />
      </Stack.V>
      <Button
        size="small"
        fontWeight="normal"
        label={t(mes.connect)}
        onClick={() => {
          handleMeetingNavigation(id, model);
          hideNotify(id);
        }}
      />
    </>
  );

  const renderMeetingStarted = ({ id, model, label }) => (
    <>
      <Badge
        className={scss.badgeButton}
        size="xxlarge"
        bgColor={theme.fullPalette.red}
        borderColor={theme.fullPalette.red}
        value={<Icon type="call_off" color={theme.palette.white} />}
        onClick={() => hideNotify(id)}
      />
      <EventAvatar />
      <NotifyLabel text={label} />
      <Badge
        className={scss.badgeButton}
        size="xxlarge"
        bgColor={theme.fullPalette.green}
        borderColor={theme.fullPalette.green}
        value={<Icon type="call" color={theme.palette.white} />}
        onClick={() => {
          handleMeetingNavigation(id, model);
          hideNotify(id);
        }}
      />
    </>
  );

  const prepareNotificationContent = (id, model, renderFunc) => {
    const { title, data } = model;
    if (!data || !renderFunc) return null;

    const formattedTime = DateUtils.toDate(data.startDate, DATE_FORMAT_5);
    const label = `${formattedTime} ${title}`;
    const now = Date.now() / 1000;
    const minutesLeft = Math.ceil((data.startDate - now) / SECONDS_PER_MINUTE);

    return renderNotificationContent({
      renderFunc,
      id,
      model,
      minutesLeft,
      label,
    });
  };

  const createNotification = (eventType, renderFunc, extraConfig) => {
    useReduxAction(({ payload }) => {
      const id = AppUtils.createRid();
      const content = prepareNotificationContent(id, payload.model, renderFunc);
      showNotify({ id, extra: { content, ...extraConfig } });
    }, eventType);
  };

  createNotification(WS_MEETING_REMINDER_ACTOR, renderMeetingReminder, {
    hideDelay: DELAY_TO_SHOW_REMINDER_MS,
  });

  createNotification(WS_MEETING_STARTED_ACTOR, renderMeetingStarted, {
    closeIcon: false,
    expire: 'none',
  });
}

export default useScheduleMeetingNotifier;
