import { Utils } from 'mw-style-react';

export const INIT_MEETING = Utils.createRequestTypes('INIT_MEETING');
export const JOIN_MEETING = Utils.createRequestTypes('JOIN_MEETING');
export const CREATE_MEETING = Utils.createRequestTypes('CREATE_MEETING');
export const CALL_MEETING = Utils.createRequestTypes('CALL_MEETING');
export const ACCEPT_MEETING = Utils.createRequestTypes('ACCEPT_MEETING');
export const REJECT_MEETING = Utils.createRequestTypes('REJECT_MEETING');
export const RECORD_MEETING = Utils.createRequestTypes('RECORD_MEETING');
export const GET_MEETING_PARTICIPANTS = Utils.createRequestTypes(
  'GET_MEETING_PARTICIPANTS'
);

export const WS_SIP_JOIN = 'WS_SIP_JOIN';
export const WS_SIP_LEFT = 'WS_SIP_LEFT';
export const WS_SIP_CALL = 'WS_SIP_CALL';
export const WS_SIP_REJECT = 'WS_SIP_REJECT';
export const WS_SIP_END = 'WS_SIP_END';
export const WS_MEETING_REMINDER_ACTOR = 'WS_MEETING_REMINDER_ACTOR';
export const WS_MEETING_STARTED_ACTOR = 'WS_MEETING_STARTED_ACTOR';

export const MEETING_NOTIFY = {
  CALL: 'sipCall',
  ACCEPT: 'sipAccept',
  REJECT: 'sipReject',
  FAILED: 'sipFailed',
};

export const CALL_STATUS = {
  CALL: 'call',
  REJECT: 'reject',
};
