const SOUND_FOLDER = '/static/audio/';
const SOUND_FORMATS = [
  {
    extension: 'wav',
    types: ['audio/wav', 'audio/wave'],
  },
  {
    extension: 'mp3',
    types: ['audio/mpeg'],
  },
  {
    extension: 'ogg',
    types: ['audio/ogg'],
  },
];

let audio;
let audioData;

const soundPlayer = {
  play: (category, actionType, settings = {}) => {
    if (window.Audio) {
      audio = new Audio();
      audioData = SOUND_FORMATS.find((i) => {
        return i.types.find((type) => {
          return audio.canPlayType(type) !== '';
        });
      });
    }
    if (audio && audioData) {
      Object.entries(settings).forEach(([key, val]) => {
        audio[key] = val;
      });
      audio.src = `${SOUND_FOLDER}${category}/${actionType}.${audioData.extension}`;
      audio.load();
      audio.play().catch((e) => {
        console.log(`Error: Can't play audio `, e); // eslint-disable-line no-console
      });
    }
  },

  pause: () => {
    if (audio) audio.pause();
  },
};

export default soundPlayer;
