import { call, put, select, all } from 'redux-saga/effects';
import { DateUtils } from 'mw-style-react';
import { NOTIFY_LEVEL, RequestStatus, SHOW_NOTIFY } from 'constants';
import AppUtils from '@control-front-end/utils/utils';
import api, { upload } from '@control-front-end/common/sagas/api';

// Uploading a file to the server
function* uploadFile(
  file,
  accId,
  withFileFilter = false,
  uploadProgressHandler = null,
  onUploadFinished = null,
  controller = null
) {
  const path = withFileFilter ? '/upload/file' : '/upload';
  const { result, data } = yield call(upload, {
    url: `${path}/${accId}`,
    file,
    onProgress: (progress) => {
      uploadProgressHandler?.(progress);
    },
    onUploadFinished: (isDone) => {
      onUploadFinished?.(isDone);
    },
    controller,
  });
  if (result !== RequestStatus.SUCCESS) return false;
  return data.data;
}

export function* uploadBase64({ payload, callback }) {
  const accounts = yield select((state) => state.accounts);

  const { result, data } = yield call(api, {
    method: 'post',
    url: `/upload/base64/${accounts.active}`,
    body: payload,
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data.data);
  return data.data;
}

// Managing attachments
function* attachActions(method, attachments, actorId) {
  if (!attachments.length) return { result: 'success' };
  const accounts = yield select((state) => state.accounts);
  const body = attachments.map((i) => ({ attachId: i.id, actorId }));
  const { result, data } = yield call(api, {
    method,
    url: `/attachments/${accounts.active}`,
    body,
  });
  return { result, data };
}

function* workWithFile(
  file,
  config,
  accounts,
  auth,
  withFileFilter,
  uploadProgressHandler,
  onUploadFinished,
  controller
) {
  if (!file?.fileSource) return file; // If there is no fileSource, return the original object

  if (file.size > config.maxFileSize) {
    yield put({
      type: SHOW_NOTIFY.REQUEST,
      payload: {
        id: AppUtils.createRid(),
        type: NOTIFY_LEVEL.ERROR,
        label: `Exceeds the maximum upload size ${Math.round(
          config.maxFileSize / (1024 * 1024)
        )}MB`,
      },
    });
    return null;
  }

  const uploadedFile = yield call(
    uploadFile,
    file.fileSource,
    accounts.active,
    withFileFilter,
    uploadProgressHandler,
    onUploadFinished,
    controller
  );
  if (!uploadedFile) return null;

  const { id, fileName, size } = uploadedFile;
  const filePath = yield call(AppUtils.makeAppUrl, `/download/${fileName}`);

  return {
    id,
    status: 'new',
    accId: accounts.active,
    fileName,
    type: file.type,
    title: file.label,
    size,
    createdAt: DateUtils.unixtime(),
    userId: auth.id,
    userAvatar: auth.avatar,
    user: { nick: auth.nick },
    filePath,
  };
}

/**
 * Creates an array of attachments before sending to the server
 * @param files
 * @param withFileFilter
 * @param uploadProgressFiles
 * @returns {IterableIterator<*>}
 */
export function* makeAttach(files, withFileFilter, uploadProgressFiles) {
  if (!files && !uploadProgressFiles) return [];

  const attachments = [];
  const [config, accounts, auth] = yield all([
    select((state) => state.config),
    select((state) => state.accounts),
    select((state) => state.auth),
  ]);

  function* processFile(
    file,
    uploadProgressHandler = null,
    onUploadFinished = null,
    controller = null
  ) {
    const attach = yield call(
      workWithFile,
      file,
      config,
      accounts,
      auth,
      withFileFilter,
      uploadProgressHandler,
      onUploadFinished,
      controller
    );
    if (attach) attachments.push(attach);
  }

  if (files) {
    yield all(files.map((file) => call(processFile, file)));
  }

  if (uploadProgressFiles) {
    yield all(
      uploadProgressFiles.map(
        ({ file, uploadProgressHandler, onUploadFinished, abortController }) =>
          call(
            processFile,
            file,
            uploadProgressHandler,
            onUploadFinished,
            abortController?.signal
          )
      )
    );
  }

  return attachments;
}

/**
 * Attaches an array of attachments to an event or reaction
 * @param attachments
 * @param actorId
 * @returns {IterableIterator<*>}
 */
export function* bindAttach(attachments, actorId) {
  return yield call(attachActions, 'post', attachments, actorId);
}

/**
 * Detaches an array of attachments from an event or reaction
 * @param attachments
 * @param actorId
 * @returns {IterableIterator<*>}
 */
export function* unBindAttach(attachments, actorId) {
  return yield call(attachActions, 'delete', attachments, actorId);
}
