import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Label, cr } from 'mw-style-react';

import ResizableCells from '@control-front-end/common/components/ResizableCells';
import { EXP_NODE } from '@control-front-end/common/constants/graphActors';
import useExpandActiveCell from '@control-front-end/app/src/routes/ActorsGraph/routers/Graph/hooks/useExpandActiveCell';
import { toCellCoord } from '@control-front-end/utils/modules/utilsCellCoords';
import { useGetGraphCoord } from 'hooks';

import { GRAPH_CELL_SIZE } from 'constants';

import scss from './ActiveCell.scss';

const BLINK_PAUSE_TIME_MS = 1000;
const GRID_OFFSET = 1;

function ActiveCell({ cy, handleSaveActorLayerSettings, selectedCells = [] }) {
  const [size, setSize] = useState({
    width: GRAPH_CELL_SIZE,
    height: GRAPH_CELL_SIZE,
  });
  const [activeCellPosition, setActiveCellPosition] = useState({ x: 0, y: 0 });
  const [resizableKey, setResizableKey] = useState(0);
  const [isResizableEnabled, setResizableEnabled] = useState(true);
  const blinkActiveCellSettings = useSelector(
    (state) => state.settings?.blinkActiveCell
  );
  const [isBlinking, setBlinking] = useState(true);
  const { expandActiveCell } = useExpandActiveCell({
    cy,
    handleSaveActorLayerSettings,
  });

  const containerResizableBoxRef = useRef(null);
  const containerRefActiveCell = useRef(null);
  const prevOffset = useRef(EXP_NODE.offset.none);
  const pauseTimeout = useRef(null);

  const pauseBlinking = () => {
    if (!blinkActiveCellSettings) return;
    setBlinking(false);
    if (pauseTimeout.current) clearTimeout(pauseTimeout.current);

    pauseTimeout.current = setTimeout(() => {
      setBlinking(true);
    }, BLINK_PAUSE_TIME_MS);
  };

  const lastSelectedCell =
    selectedCells.length > 0 ? selectedCells.at(-1) : { x: 0, y: 0 };

  useEffect(() => {
    if (selectedCells.length) {
      setActiveCellPosition({ x: lastSelectedCell.x, y: lastSelectedCell.y });
      pauseBlinking();
    }
  }, [selectedCells]);

  const updateContainerTransform = useCallback(
    ({ pan, zoom }) => {
      if (!pan || !zoom) return;
      const transformStyle = `translate(${pan.x}px, ${pan.y}px) scale(${zoom})`;

      [
        containerResizableBoxRef.current,
        containerRefActiveCell.current,
      ].forEach((ref) => {
        if (ref) {
          ref.style.transform = transformStyle;
        }
      });
    },
    [containerRefActiveCell.current, containerResizableBoxRef.current]
  );

  useGetGraphCoord(cy, updateContainerTransform);

  useEffect(() => {
    const handleHoverStart = () => setResizableEnabled(false);
    const handleHoverEnd = () => setResizableEnabled(true);

    cy.on('mouseover', 'node', handleHoverStart);
    cy.on('mouseout', 'node', handleHoverEnd);
    return () => {
      cy.off('mouseover', 'node', handleHoverStart);
      cy.off('mouseout', 'node', handleHoverEnd);
    };
  }, [cy]);

  const resetSizeAndPosition = () => {
    // Incrementing the resizableKey forces React to recreate the ResizableCells component for reset value.
    setResizableKey((prevKey) => prevKey + 1);
    setSize({ width: GRAPH_CELL_SIZE, height: GRAPH_CELL_SIZE });
    setActiveCellPosition({ x: lastSelectedCell.x, y: lastSelectedCell.y });
    prevOffset.current = EXP_NODE.offset.none;
  };
  const toggleCyInteractions = (enabled) => {
    cy.panningEnabled(enabled);
    cy.boxSelectionEnabled(enabled);
    cy.zoomingEnabled(enabled);
    setBlinking(enabled);
  };

  const handleResizeStop = (e, { size: newSize, offset: newOffset }) => {
    toggleCyInteractions(true);
    resetSizeAndPosition();
    expandActiveCell({
      position: { x: lastSelectedCell.x, y: lastSelectedCell.y },
      newSize,
      newOffset,
    });
  };

  if (!cy) return null;

  const handleOffsetChange = (offset) => {
    const deltaPosition = {
      x: offset.left - prevOffset.current.left,
      y: offset.top - prevOffset.current.top,
    };
    setActiveCellPosition((position) => ({
      x: position.x - deltaPosition.x,
      y: position.y - deltaPosition.y,
    }));

    prevOffset.current = offset;
  };

  const getActiveCellStyle = (position, isLast) => ({
    transform: `translate(${
      ((isLast ? activeCellPosition.x : position?.x) || 0) -
      GRAPH_CELL_SIZE / 2 -
      GRID_OFFSET / 2
    }px, ${
      ((isLast ? activeCellPosition.y : position?.y) || 0) -
      GRAPH_CELL_SIZE / 2 -
      GRID_OFFSET / 2
    }px)`,
    width: `${(size.width || GRAPH_CELL_SIZE) + GRID_OFFSET}px`,
    height: `${(size.height || GRAPH_CELL_SIZE) + GRID_OFFSET}px`,
    backgroundSize: `${GRAPH_CELL_SIZE}px ${GRAPH_CELL_SIZE}px`,
  });

  const getCellRangeStyle = (position, opacity) => {
    if (
      position?.x === undefined ||
      position?.y === undefined ||
      position?.x2 === undefined ||
      position?.y2 === undefined
    ) {
      return {};
    }

    return {
      position: 'absolute',
      left:
        (position.x2 >= position.x ? 0 : position.x2 - position.x) -
        GRID_OFFSET / 2,
      top:
        (position.y2 >= position.y ? 0 : position.y2 - position.y) -
        GRID_OFFSET / 2,
      width: `${
        Math.abs((position.x2 ?? 0) - (position.x ?? 0)) +
        GRAPH_CELL_SIZE +
        GRID_OFFSET
      }px`,
      height: `${
        Math.abs((position.y2 ?? 0) - (position.y ?? 0)) +
        GRAPH_CELL_SIZE +
        GRID_OFFSET
      }px`,
      backgroundSize: `${GRAPH_CELL_SIZE}px ${GRAPH_CELL_SIZE}px`,
      opacity,
    };
  };

  const shouldShowRange = (position) => {
    return (
      position &&
      position.x2 !== undefined &&
      position.y2 !== undefined &&
      (position.x2 !== position.x || position.y2 !== position.y)
    );
  };

  const isSingleCell =
    selectedCells.length === 1 &&
    selectedCells[0].x === selectedCells[0].x2 &&
    selectedCells[0].y === selectedCells[0].y2;

  return (
    <>
      <div
        className={scss.container}
        ref={containerResizableBoxRef}
        style={{ zIndex: 6 }}
        key="overlay-container"
      >
        {selectedCells.map(({ isOccupied, ...position }, index, array) => {
          const isLast = index === array.length - 1;
          const { x = '', y = '' } = toCellCoord(position);
          return (
            <div
              style={getActiveCellStyle(position, isLast)}
              className={scss.activeCell}
              key={`${position.x}-${position.y}-${position.x2}-${position.y2}`}
            >
              {shouldShowRange(position) ? (
                <div
                  className={scss.activeCellBorder}
                  style={getCellRangeStyle(position, 0.4)}
                />
              ) : null}
              <div
                className={cn(scss.activeCellBorder, {
                  [scss.blink]: isBlinking && blinkActiveCellSettings && isLast,
                })}
              />
              {cr([
                !isOccupied,
                <Label
                  className={scss.cellCoordLabel}
                  fontSize="small"
                  value={x || y ? `${x}, ${y}` : ''}
                  textAlign="end"
                  color="gray"
                />,
              ])}
            </div>
          );
        })}
        {isResizableEnabled && isSingleCell ? (
          <ResizableCells
            key={resizableKey}
            initialSize={{
              width: GRAPH_CELL_SIZE,
              height: GRAPH_CELL_SIZE,
            }}
            resizeHandles={['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne']}
            onResizeStart={() => {
              toggleCyInteractions(false);
            }}
            width={size.width}
            height={size.height}
            offset={EXP_NODE.offset.none}
            onSizeChange={setSize}
            onOffsetChange={handleOffsetChange}
            onResizeStop={handleResizeStop}
            minConstraints={[GRAPH_CELL_SIZE, GRAPH_CELL_SIZE]}
            position={lastSelectedCell || { x: 0, y: 0 }}
          >
            <div />
          </ResizableCells>
        ) : null}
      </div>
      <div
        className={scss.container}
        ref={containerRefActiveCell}
        style={{ zIndex: -1 }}
        key="underlay-container"
      >
        {selectedCells.map(({ isOccupied, ...position }, index, array) => {
          const isLast = index === array.length - 1;
          return (
            <div
              className={scss.activeCell}
              style={getActiveCellStyle(position, isLast)}
              key={`${position.x}-${position.y}-${position.x2}-${position.y2}`}
            >
              {shouldShowRange(position) ? (
                <div
                  className={scss.activeCellbg}
                  style={getCellRangeStyle(position, 0.4)}
                />
              ) : null}
              <div
                className={cn(scss.activeCellbg, {
                  [scss.blink]: isBlinking && blinkActiveCellSettings && isLast,
                })}
              />
            </div>
          );
        })}
      </div>
    </>
  );
}

ActiveCell.propTypes = {
  cy: PropTypes.object,
  handleSaveActorLayerSettings: PropTypes.func,
  selectedCells: PropTypes.array,
};

export default ActiveCell;
