import React, { useRef, useMemo, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  Icon,
  DateUtils,
  Utils,
  Tooltip,
  Label,
  Stack,
  Badge,
  Clipboard,
  Space,
  CorezoidLightTheme as theme,
} from 'mw-style-react';
import { useURLSettings, useIntl, useMobileViewport } from 'hooks';
import AppUtils from '@control-front-end/utils/utils';
import {
  DATE_FORMAT_1,
  DATE_FORMAT_5,
  NOTIFY_LEVEL,
  SHOW_NOTIFY,
} from 'constants';
import {
  STARRED_ACTOR,
  UPDATE_ACTOR,
} from '@control-front-end/common/constants/graphActors';
import { URL_PARAMS } from '@control-front-end/common/constants/urlParams';
import UserAvatar from '@control-front-end/common/components/UserAvatar';
import UsersAvatars from '@control-front-end/common/components/UsersAvatars';
import history from '@control-front-end/app/src/store/history';
import { makeGetEvent } from './selectors';
import { makeGetActiveActor } from '../../selectors/graphs';
import SmartChipPreview from '../SmartChipPreview/SmartChipPreview';
import ActorChips from '../ActorChips';
import LastReaction from './components/LastReaction';
import mes from './intl';
import './EventListItem.scss';

const CONTAINER_BG_COLOR_PERCENT = 20;

/**
 * Event list element
 */
const EventListItem = forwardRef((props, ref) => {
  const {
    id,
    showStar = true,
    showCopy = true,
    showStat = true,
    compactView = false,
    openInNewTab = false,
    onClick,
    handleUpdateLinkedEvent,
  } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const itemFromState = useSelector((state) => makeGetEvent(state, props));
  const item = props.item || itemFromState;
  const activeEvent = useSelector(makeGetActiveActor);
  const isMobile = useMobileViewport();
  const descrRef = useRef();
  const { eventDoubleClick, openActorViewInMobile } = useURLSettings();
  const isActive = activeEvent?.id === id;

  useImperativeHandle(ref, () => ({
    activate: () => onClick?.(id),
    isActive: () => isActive,
  }));

  const {
    accId,
    starred,
    title,
    userId,
    user,
    lastReaction,
    description: descr,
    access,
    unread,
    unreadReactions,
    activeMeeting,
    color,
  } = item;
  const titleSanitized = Utils.sanitizeHtml(
    Utils.stripHtml(Utils.nToBr(title)),
    {
      allowedTags: false,
    }
  );
  const titleFormatted = compactView
    ? titleSanitized
    : AppUtils.hilightLinks(titleSanitized);
  const eventLink = AppUtils.makeUrl(
    `/actors_graph/${AppUtils.makeShortAccountId(accId)}/view/${id}`
  );
  const titleWithLink = `<a href=${eventLink}>${titleFormatted}</a>`;
  const description = Utils.removeHtmlTags(
    AppUtils.makeDescription({
      str: descr,
      accId,
      chip: true,
      noNewLines: true,
    })
  );

  // Open actor-event
  const openActorView = ({ tab }) => {
    if (isMobile && !openActorViewInMobile) return;
    const isIframeView = !!window.frameElement;
    let actorViewUrl = AppUtils.makeUrl(
      `/actors_graph/${AppUtils.makeShortAccountId(accId)}/view/${id}`
    );
    if (tab) actorViewUrl = `${actorViewUrl}?tab=${tab}`;
    if (isIframeView || openInNewTab) {
      AppUtils.openTabWithNewId(actorViewUrl);
    } else {
      history.push(actorViewUrl);
    }
  };

  // Get clicked tab name
  const getClickedTab = (e) => {
    const element = e.target.closest('[class*="tab:"]');
    if (!element) return null;
    return element.className
      .split(' ')
      .find((cls) => cls.startsWith('tab:'))
      ?.split(':')[1];
  };

  // Set get parameter with active tab
  const setActiveTab = (tab) => {
    const url = new URL(document.location);
    if (tab) {
      url.searchParams.set(URL_PARAMS.tab, tab);
    } else {
      url.searchParams.delete(URL_PARAMS.tab);
    }
    window.history.pushState({}, '', url);
  };

  // Go to event by link in panel mode
  const handleClick = (e) => {
    if (
      e.target.closest('div[class^="event__chips__item"][class*="editable"]') ||
      e.target.closest('div[class^="event__calendar"]') ||
      e.target.closest('div[class^="scp"]')
    )
      return;
    const tab = getClickedTab(e);
    if (onClick) {
      setActiveTab(tab);
      onClick(id);
    } else {
      openActorView({ tab });
    }
  };

  // Mark as favorite event
  const handleStar = (e) => {
    e.stopPropagation();
    dispatch({
      type: STARRED_ACTOR.REQUEST,
      payload: { actorId: id, starred: !starred },
    });
  };

  // Copy the event reference
  const handleCopyLink = (e) => {
    e.stopPropagation();
    const link = `${
      document.location.origin
    }/actors_graph/${AppUtils.makeShortAccountId(accId)}/view/${id}`;
    Clipboard.copy(link);
    dispatch({
      type: SHOW_NOTIFY.REQUEST,
      payload: {
        id: AppUtils.createRid(),
        type: NOTIFY_LEVEL.SUCCESS,
        label: t(mes.linkWasCopied),
      },
    });
  };

  const handleUpdateActor = (newData) => {
    dispatch({
      type: UPDATE_ACTOR.REQUEST,
      payload: {
        ...item,
        ...newData,
      },
      callback: (actor) => {
        if (handleUpdateLinkedEvent) {
          handleUpdateLinkedEvent({ actor });
        }
      },
    });
  };

  const indicatorLine = useMemo(() => {
    return color ? (
      <div
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: '4px',
          backgroundColor: color,
        }}
      />
    ) : null;
  }, [color]);

  const containerBgColor = useMemo(() => {
    return AppUtils.getPercentColor(color, CONTAINER_BG_COLOR_PERCENT);
  }, [color]);

  const renderFullView = () => {
    return (
      <div
        id={`event__${id}`}
        styleName={cn('le__item', { active: isActive })}
        onClick={handleClick}
        onDoubleClick={eventDoubleClick ? openActorView : null}
        style={{ backgroundColor: containerBgColor }}
      >
        {indicatorLine}
        <SmartChipPreview accId={accId} containerRef={descrRef} />
        <div styleName="le__item__info">
          <div styleName="le__item__header">
            {unread ? <div styleName="le__item__new" /> : null}
            {showStar ? (
              <Tooltip value={t(mes.markAsFavorite)}>
                <Icon
                  styleName="le__item__icon"
                  type={starred ? 'star_full' : 'star_empty'}
                  color={starred ? theme.fullPalette.yellow : null}
                  onClick={handleStar}
                />
              </Tooltip>
            ) : null}
            {showCopy ? (
              <Tooltip value={t(mes.copyEventLink)}>
                <Icon
                  styleName="le__item__icon"
                  type="link"
                  onClick={handleCopyLink}
                />
              </Tooltip>
            ) : null}
            <div styleName="le__item__title">
              <div
                styleName="le__item__owner"
                title={`${user.nick} (${t(mes.evOwner)})`}
              >
                <Tooltip value={user.type === 'api' ? `(${t(mes.robot)})` : ''}>
                  <div styleName="le__item__owner__avatar__box">
                    <UserAvatar
                      styleName="le__item__owner__avatar"
                      size="medium"
                      src={user.avatar}
                      label={user.nick}
                      userType={user.type}
                    />
                  </div>
                </Tooltip>
                <Label styleName="le__item__owner__name" value={user.nick} />
              </div>
              <Tooltip value={titleSanitized} breakWord>
                <div
                  styleName="le__item__title__text"
                  onClick={(e) => {
                    if (e.target.tagName === 'A') e.stopPropagation();
                  }}
                  dangerouslySetInnerHTML={{
                    __html: titleWithLink,
                  }}
                />
              </Tooltip>
            </div>
            <div styleName="le__item__name">
              <UsersAvatars
                users={access.filter((i) => i.userId !== userId)}
                size="small"
              />
            </div>
          </div>
          {descr ? (
            <div ref={descrRef} styleName="le__item__descr__wrap">
              <div
                styleName="le__item__descr"
                onClick={(e) => {
                  if (e.target.tagName === 'A') e.stopPropagation();
                }}
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </div>
          ) : null}
          <LastReaction
            lastReaction={lastReaction}
            isActive={isActive}
            compactView={compactView}
          />
          <div styleName="le__item__update">
            <ActorChips
              actor={item}
              isEvent
              showStat={showStat}
              showReadOnly={false}
              handleUpdateActor={handleUpdateActor}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderCompactView = () => {
    const updateTime = lastReaction ? lastReaction.createdAt : item.updatedAt;
    const formattedUpdateTime =
      updateTime > DateUtils.todayStart()
        ? DateUtils.toDate(updateTime, DATE_FORMAT_5)
        : DateUtils.toDate(updateTime, DATE_FORMAT_1);
    return (
      <Space bottom top size="micro">
        <div
          id={`event__${id}`}
          styleName={cn('le__item compact', { active: isActive })}
          onClick={handleClick}
          onDoubleClick={eventDoubleClick ? openActorView : null}
          style={{ backgroundColor: containerBgColor }}
        >
          {indicatorLine}
          <div styleName={cn('le__item__info compact', { active: isActive })}>
            <div
              styleName={cn('le__item__header compact', {
                active: isActive,
              })}
            >
              {unread ? <div styleName="le__item__new compact" /> : null}
              <div styleName="le__item__title">
                <Tooltip value={titleFormatted} breakWord>
                  <div
                    styleName={cn('le__item__title__text compact', {
                      active: isActive,
                    })}
                    dangerouslySetInnerHTML={{
                      __html: titleFormatted,
                    }}
                  />
                </Tooltip>
                <div styleName="le__item__title__time">
                  <Stack horizontal size="xsmall">
                    <Space right size={isMobile ? 'xsmall' : 'xxsmall'}>
                      <Label value={formattedUpdateTime} />
                    </Space>
                    {unreadReactions ? (
                      <Badge
                        size="xlarge"
                        value={
                          <Label
                            fontSize="xsmall"
                            value={
                              unreadReactions > 99 ? '+99' : unreadReactions
                            }
                          />
                        }
                      />
                    ) : null}
                  </Stack>
                </div>
              </div>
            </div>
            <LastReaction
              lastReaction={lastReaction}
              isActive={isActive}
              compactView={compactView}
              activeMeeting={activeMeeting}
            />
          </div>
        </div>
      </Space>
    );
  };

  return compactView ? renderCompactView() : renderFullView();
});

EventListItem.propTypes = {
  id: PropTypes.string.isRequired,
  showStar: PropTypes.bool,
  showCopy: PropTypes.bool,
  showStat: PropTypes.bool,
  compactView: PropTypes.bool,
  onClick: PropTypes.func,
  handleUpdateLinkedEvent: PropTypes.func,
};

export default EventListItem;
